.content__level--1 {
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
}

.content__level-1 {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
}

.content__level-2 {
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
}

.content__main--flush-inner {
  padding-top: 0;
  margin-top: 0;
}